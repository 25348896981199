.wrapper {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.sticky {
  position: sticky;
  top: 0px;
  z-index: 9;
}

.container {
  width: 100%;
  overflow-x: auto;
  overflow-y: overlay;
  background: #fff;
  flex-grow: 1;
  z-index: 1;
}

.text100 {
  font-weight: 100;
}

.textSecondary {
  color: rgb(115 115 115);
}

.textRegular {
  color: #000;
}

.tr {
  border-bottom: 1px solid rgb(213 213 221) !important;
}

.th,
.td {
  background-color: white;
  text-align: left;
  vertical-align: top;
  padding: 1rem 0;
  padding-left: 0.25rem;
  border-top: none !important;
  border-bottom: none !important;
  word-break: break-all;
}

.th:first-of-type,
.td:first-of-type {
  padding-left: 1rem;
}

.th:last-of-type,
.td:last-of-type {
  padding-right: 1rem;
}

@media (min-width: 992px) {
  .th:first-of-type,
  .td:first-of-type {
    padding-left: 1.25rem;
  }

  .th:last-of-type,
  .td:last-of-type {
    padding-right: 1.25rem;
  }
}
