$color-blue: #1a237e;
$color-blue-hover: #0085c1;
$color-grey: #e5e5e5;
$color-white: #ffffff;
$border-radius: 20px;
$theme-main-color: #fdcd03;
$base-gap: 1rem;
$shadow-color: #ccc;
$shadow-blur: 12px;
$shadow-offset-x: 0;
$shadow-offset-y: 0;
$shadow-box: $shadow-offset-x $shadow-offset-y $shadow-blur $shadow-color;
