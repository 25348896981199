:root {
  --top-start: 0px;
  --top-end: 0px;
}

/* ===== Scrollbar CSS ===== */
* {
  scrollbar-width: auto;
  scrollbar-color: #828282 #ffffff;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #828282;
    border-radius: 5px;
    border: 3px solid #ffffff;
  }
}

.company-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: rgb(115, 115, 115);
  font-weight: normal;
  margin-block: 0.25rem;
}

body {
  overscroll-behavior: contain;
}

.transparent {
  background-color: transparent;
}

.mobile-form-body {
  @media (max-width: 991px) {
    overflow: hidden;
    max-height: 100%;
    position: fixed;
    top: 0;
    -webkit-overflow-scrolling: touch;
  }
}

// portal modal classes

.modal-body .pagination-settings {
  display: none;
}

.w-\[26\.5rem\] {
  width: 26.5rem;
}

.portal {
  background-color: rgba(0, 0, 0, 0.32);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &-content-wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  &-confirm {
    width: 100%;

    @media (min-width: 992px) {
      width: 31.5rem;
    }

    @media (min-width: 576px) {
      max-width: 30.25rem;
    }
  }
}

.break-word {
  word-break: break-word;
}

.link {
  color: #007aff;
}

.w-max-content {
  width: max-content;
}

.w-\[14\.75rem\] {
  width: 14.75rem;
}

.w-\[10\.75rem\] {
  width: 10.75rem;
}

.w-12rem {
  width: 12rem;
}

.w-auto {
  width: auto;
}

.pointer {
  width: 15px;
  height: 15px;
  background-color: white;
  position: absolute;
  border-top-width: 1px;
  border-left-width: 1px;
  border-color: rgb(213, 213, 221);
}

.edit-delete {
  width: auto;
  right: 0px;
  left: auto;
}

.edit-delete-item {
  display: block;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  transition-property:
    color,
    background-color,
    border-color,
    text-decoration-color,
    fill,
    stroke,
    -webkit-text-decoration-color;
  transition-duration: 150ms;
}

.select-inner {
  --tw-divide-y-reverse: 0;
  margin: 0px;
  max-height: calc(
    calc(calc(0.9375rem * 1.3334 + 1.25rem) * 6) + calc(5 * calc(1px * calc(1 - var(--tw-divide-y-reverse))))
  );
  list-style-type: none;
}

.error {
  color: #ee1a1a;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.filter-container__item {
  flex-grow: 1;
  max-width: 300px;
  min-width: 200px;
}

.filter-container__item_selectable {
  flex-grow: 1;
  max-width: 250px;
  min-width: 200px;
}

.filter-container__button-export {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  max-width: unset;
  min-width: unset;
}

.filter {
  background-color: #fff;
  height: 2.5rem;
  padding: 0.5rem 1rem;
}

.search {
  height: 2.5rem;
  align-self: flex-end;
}

.filter-dark {
  background-color: #fafafa;
  height: 2.5rem;
  padding: 0.5rem 1rem;
}

.pointer {
  width: 15px;
  height: 15px;
  background-color: white;
  position: absolute;
  border-top-width: 1px;
  border-left-width: 1px;
  border-color: rgb(213, 213, 221);
}

.input-filter-field {
  border: none !important;
  background-color: rgb(250, 250, 250);
  color: rgb(0, 0, 0);
  width: 100%;
}

.input-filter-field:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-search {
  padding-right: 1em;
}

.image-marker {
  position: absolute;

  &:hover {
    z-index: -1;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.import {
  &-dropzone {
    height: 190px;
    width: 100%;
    border: 2px dashed #ccc;
  }

  &-active {
    border: 2px dashed darkgreen;
  }

  &-dialog {
    background-color: #f4f4f7;
  }
}

.tariff {
  &-wrapper {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 0.5rem;
  }

  &-image {
    width: 70px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-label {
    @media (min-width: 992px) {
      margin-left: 8px;
    }

    @media (max-width: 767px) {
      margin-left: 8px;
    }
  }

  display: flex;
  border: 2px solid red;
  flex-direction: column;
  border-radius: 8px;
  background-color: '#f2f2f2';
  border-width: 0;
  height: fit-content;
  padding: 15px 8px 10px 8px;
  align-items: center;
  overflow: visible;
  cursor: pointer;

  &-active {
    box-shadow:
      4px 4px 10px rgba(0, 0, 0, 0.2),
      -2px -2px 10px rgba(0, 0, 0, 0.2);
  }

  &-title {
    font-size: 13px;
    color: black;
    font-weight: '700';
    line-height: 13px;
    margin-top: 5px;
  }

  &-price {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 2px;
  }

  &-time {
    font-size: 11px;
    line-height: 11px;
  }
}

.autocomplete-wrapper {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.outline-none {
  outline: none;
}

.scroll-x {
  overflow-x: scroll;
}

.currency-sign {
  border: 2px solid #fdcd03;
  color: #0a0a0a;
  border-radius: 50%;
  height: 20px;
  line-height: 15px;
  min-width: 20px;
  display: inline-block;
  margin: 0 5px 0 0;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: center;
}

.stop-icon {
  background-color: #fdcd03;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    color: black;
    font-weight: 600;
  }
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.dark-form-control {
  background-color: rgba(242, 242, 242, 0.94);
}

.validate-error {
  border: 1px solid rgb(241, 79, 79);

  &-message {
    color: rgb(241, 79, 79);
  }
}

.manager-info-text {
  display: inline-block;
  margin-top: 10px;
}

button.ride-inactive:hover {
  background-color: rgba(238, 26, 26, 0.1);
}

.settings {
  justify-content: space-between;
}

.one-line-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.many-lines-text {
  overflow: hidden;
  white-space: break-spaces !important;
}
.select-toggler {
  min-height: 48px;
}
.select-toggler span {
  white-space: break-spaces !important;
  word-break: break-word;
}

.zero-height-input {
  box-sizing: border-box;
  height: 0 !important;
  overflow: hidden;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  outline: none !important;
  border: none !important;
}

.min-w-0 {
  min-width: 0;
}

.min-w-24 {
  min-width: 6rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.gap-4 {
  gap: $base-gap;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-self-stretch {
  align-self: stretch;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

// Override Yandex maps classes
// ======= start =======
.ymaps-2-1-79-map-copyrights-promo {
  display: none !important;
}

.ymaps-2-1-79-copyright__agreement {
  display: none;
}

// ======= end =======

.default-policy-btn {
  @media (max-width: 30.75rem) {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.import-dropzone-example {
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
}

.swipe {
  animation: swipeVertical 0.2s ease-in forwards;
}

.location-pin {
  z-index: 9;
  top: 50%;
  left: 50%;
}

.map-popup {
  box-shadow:
    -5px -5px 10px rgba(0, 0, 0, 0.3),
    5px 5px 10px rgba(0, 0, 0, 0.3);
  max-width: 300;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-100% - 15px));

  &-close {
    left: 105%;
    box-shadow:
      -3px -3px 10px rgba(0, 0, 0, 0.3),
      5px 5px 10px rgba(0, 0, 0, 0.3);
  }

  &-pointer {
    border-right-width: 1px;
    border-bottom-width: 1px;
    width: 15px;
    height: 15px;
    transform: rotate(45deg) translate(-60%);
    left: 50%;
  }
}

.disabling-overlay {
  position: absolute;
  inset: 0;
  cursor: not-allowed;
  background-color: #fff;
  opacity: 0.7;
}

@keyframes swipeVertical {
  from {
    top: var(--top-start);
  }

  to {
    top: var(--top-end);
  }
}

.hide {
  animation: fade-out 0.2s ease-in forwards;
}

.show {
  animation: fade-in 0.2s ease-in;
}

.scaled-up {
  animation: scale-up 0.2s ease-in;
}

.scaled-down {
  animation: scale-down 0.2s ease-in forwards;
}

.slide-in {
  animation: slide-in 0.3s cubic-bezier(0, 0, 0, 1);
}

.slide-out {
  animation: slide-out 0.2s ease-in forwards;
}

.bottom-slide-in {
  animation: bottom-slide-in 0.3s cubic-bezier(0, 0, 0, 1) forwards;
}
.bottom-slide-in-600 {
  animation: bottom-slide-in-600 0.3s cubic-bezier(0, 0, 0, 1) forwards;
}

.bottom-slide-out {
  animation: bottom-slide-out 0.15s ease-in forwards;
}

.shadow-box {
  box-shadow: $shadow-box;
}

.hovered-dropdown {
  position: relative;

  &__popup {
    animation: fade-out;
    background-color: #fff;
    position: absolute;
    top: 100%;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform 0.2s ease;
    overflow-y: auto;
    min-width: 100%;
    max-width: 300px;
    width: max-content;
    left: 0;
    border-radius: 8px;
    border-bottom-right-radius: 8px;

    &_open {
      animation: fade-in 0.1s ease-in;
      box-shadow: 0 12px 18px #919090;
      transform: scaleY(1);
    }
  }

  &__area {
    display: flex;
    align-items: center;
  }
}

.profile {
  &__item {
    padding: 0.5rem;

    &_active {
      background-color: rgba($theme-main-color, 0.1);

      .vip & {
        background-color: rgba(0, 0, 0, 0.1);
      }

      span,
      p {
        mix-blend-mode: darken;
      }
    }
  }
}

.dropdown-icon {
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  transform: rotate(0deg);
  transition: all 0.5s;

  &_open {
    transform: rotateX(180deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slide-in {
  from {
    left: -248px;
  }

  to {
    left: 0;
  }
}

@keyframes slide-out {
  from {
    left: 0;
  }

  to {
    left: -248px;
  }
}

@keyframes bottom-slide-in {
  from {
    max-height: 0px;
  }

  10% {
    max-height: 0px;
  }

  to {
    max-height: 500px;
  }
}
@keyframes bottom-slide-in-600 {
  from {
    max-height: 0px;
  }

  10% {
    max-height: 0px;
  }

  to {
    max-height: 600px;
  }
}

@keyframes bottom-slide-out {
  from {
    max-height: 500px;
  }

  to {
    max-height: 0px;
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes scale-down {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.9);
  }
}
